@import '@/styles/vars.scss';

.block__phone {
  width: 100%;
  background: rgba(128, 156, 129, 0.1) !important;

  p {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: -1px;
    margin-left: 9px;
    color: #809c81;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      .isPhoneScreen {
        display: none;
      }
    }
  }
  @media screen and (max-width: 698px) {
    display: none;
    width: 36px;
    height: 32px !important;
    min-width: 32px !important;
    padding: 0px !important;
    span,
    p {
      display: none;
    }
    svg {
      fill: $green-lite;
      width: 32px;
      height: 32px !important;
      padding: 6px;
    }
  }
}

.modal_content {
  min-width: 200px;
  text-align: center;
  &__title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* identical to box height */
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #3a3d42;
  }

  &__copy {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: $green;

    transition: all 0.2s linear;

    svg {
      path,
      rect {
        transition: all 0.2s linear;
      }
      margin-left: 6px;
    }

    &:hover {
      color: $orange;

      svg {
        path,
        rect {
          fill: $orange;
        }
      }
    }
  }
}
