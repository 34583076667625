@import '@/styles/vars.scss';

.datePicker {
  :global {
    .PrivatePickersSlideTransition-root > .Mui-selected {
      background: #000 !important;
    }
  }
}

.formControl {
  width: 100% !important;
  max-width: 100% !important;
  flex-direction: row !important;
  gap: 24px;
  label {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif !important;
    flex: 1;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  div {
    flex: 2 !important;
    max-width: 256px;
  }

  .textField {
    display: block !important;
    width: 100%;
    border-color: rgba($green-lite, 0.1) !important;
    border: none !important;
    border-radius: 10px;
    padding: 12px 15px !important;
    background: rgba($green-lite, 0.1);
    flex: 2 !important;

    div,
    fieldset {
      width: 100%;
      outline: none;
      border: none !important;
    }

    input {
      width: 100%;
      padding: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      font-family: 'Raleway', sans-serif !important;
      &::placeholder {
        color: $green-lite !important;
        opacity: 0.9 !important;
      }
      color: $green-lite !important;
    }
  }
}

.input {
  width: 100%;
}
