@import '@/styles/vars.scss';

.announcements {
  .page_content {
    max-width: 950px;
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 61px;

    &__info {
      flex: 0 1 64%;
      max-width: 100%;

      .info__block {
        padding: 18px 24px;
        background: #ffffff;
        border-radius: 20px;
        margin-bottom: 8px;
        max-width: 660px;
        width: 100%;
      }

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
        color: $black;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 128.9%;
        color: $black;
        margin-bottom: 17px;
        word-wrap: break-word;
        white-space: break-spaces;

        &:last-child {
          font-weight: 700;
          font-size: 16px;
          color: #33a03e;
          margin-bottom: 0;
        }
      }
    }

    &__contacts {
      margin-left: 17px;
      flex: 0 1 28%;
      @media screen and (max-width: 475px) {
        margin-left: 0px;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        width: 100%;
      }
      .block__controlls {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        a {
          text-decoration: none;
        }

        .messagesBlocked {
          background-color: $green-lite !important;
          opacity: 0.5;
          &:hover {
            cursor: not-allowed;
          }
        }

        button {
          padding-left: 30px !important;
          padding-right: 30px !important;
          @media screen and (max-width: 698px) {
            display: none;
          }
        }

        .contacts__favorite {
          display: flex;
          align-items: center;
          cursor: pointer;

          font-weight: 500;
          font-size: 12px;

          color: $green-lite;
          @media screen and (max-width: 768px) {
            & > p {
              display: none;
            }
          }

          .like {
            height: 14px;
            fill: $green-lite;
            fill-opacity: 1;
            column-fill: auto;
            @media screen and (min-width: 768px) {
              &:hover {
                fill: $orange;
                cursor: pointer;
              }
            }
          }

          .liked {
            stroke: transparent !important;
            fill: $orange;
            fill-opacity: 1;
          }
        }
      }

      .contacts__block {
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        background: #fff;
        border-radius: 20px;
        margin-bottom: 8px;
        gap: 8px;
        @media screen and (min-width: 475px) and (max-width: 768px) {
          width: 220px;
        }
        @media screen and (max-width: 475px) {
          display: flex;
          width: 50%;
        }
        .messageSVG {
          display: block;
          background-color: $green;
          border-radius: 50%;
          padding: 10px 0px;
          width: 40px;
          height: 40px;
          path {
            stroke: white;
          }
          @media screen and (min-width: 698px) {
            display: none;
          }
        }

        .phoneSVG {
          display: block;
          margin-left: 32px;
          @media screen and (min-width: 698px) {
            display: none;
          }
        }

        .block__phone {
          width: 100%;
          background: rgba(128, 156, 129, 0.1) !important;

          span {
            font-weight: 500;
            font-size: 12px;
            margin-bottom: -1px;
            margin-left: 9px;
            color: #809c81;
          }
        }
      }
    }

    &__without_bottom_margin {
      margin-bottom: 0px !important;
    }
  }

  .otherCards {
    h3 {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 475px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 698px) {
    .page_content {
      flex-direction: column-reverse;
      &__contacts {
        display: flex;
        gap: 8px;
        width: 100%;
        margin-left: 0px;
        .contacts__block {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          border-radius: 10px;
          max-height: 48px;

          .messageSVG {
            width: 32px;
            height: 32px;
            padding: 5px;
          }
        }
        .contacts__profile {
          align-items: center;
          justify-content: flex-start;
          padding-left: 16px;
          :global {
            .avatar {
              width: 33px;
              height: 33px;
            }
            .info {
              font-size: 12px;
            }
            .rating {
              font-size: 12px;
            }
          }
        }
        .block__controlls {
          width: 100%;
        }
      }
      &__info {
        flex: 0 1 100%;
        width: 100%;
        .info__block {
          padding: 14px 16px;
          border-radius: 10px;
          .infoTitle {
            font-size: 13px;
          }
          .infoDesc {
            font-size: 12px;
          }
          .geolocation {
            font-size: 12px;
          }
          .infoBudget {
            font-size: 12px;
          }
        }
      }
    }
    .otherCards {
      &__title {
        font-size: 10px;
      }
    }
    .like {
      background-color: $grey;
      border-radius: 50%;
      fill: $green-lite;
      width: 32px;
      height: 32px !important;
      padding: 6px;
      column-fill: auto;
      overflow: visible;
      &:hover {
        fill: $orange;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 368px) {
    .page_content {
      &__contacts {
        .contacts__profile {
          :global {
            .info {
              font-size: 10px;
            }
            .rating {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
