@import '@/styles/vars.scss';

.mobile_settings {
  position: absolute;
  right: 0;
  top: -37px;
  z-index: 2;
  display: none;
  @media screen and (max-width: 600px) {
    display: block !important;
  }
}

.top {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form {
  display: flex;
  //align-items: center;

  .form_inputs {
    flex: 0 1 100%;
    flex-grow: 1;
  }

  .form_btns {
    width: 100%;
    flex: 0 1 88px;
    //margin-top: -22px;

    @media screen and (max-width: 1200px) {
      margin-left: 10px;
    }
    @media screen and (max-width: 1050px) {
      margin-left: 0;
    }
  }

  &__top_row,
  &__bottom_row {
    display: flex;
    align-items: initial;
    flex-wrap: wrap;
    margin-bottom: 8px;
    grid-gap: 10px;
    @media screen and (max-width: 1150px) {
      grid-gap: 10px;
    }
    @media screen and (max-width: 800px) {
      grid-gap: 0px;
      //gap: 1%;
      justify-content: space-between;
      // margin-right: 6px;
    }
  }
  @media screen and (max-width: 500px) {
    &__top_row {
      .big_select:nth-child(6) {
        flex: 1 52%;
      }
      .big_select:nth-child(1) {
        flex: 1 100%;
      }
      .big_select:nth-child(3) {
        flex: 0 1 58% !important;
      }
      .big_select:nth-child(2) {
        flex: 1 100% !important;
      }
      .big_select:nth-child(7) {
        flex: 1 100%;
      }
      .big_select:nth-child(6) {
        flex: 1 100%;
      }
    }
  }

  .big_select,
  form_city {
    flex: 0 1 18.5%;

    :global {
      .MuiFormControl-root {
        width: 100% !important;
      }

      .MuiInputBase-formControl {
        width: 100% !important;
        min-width: initial !important;
        &input::placeholder {
          color: #3a3d42 !important;
        }
      }
    }
    @media screen and (max-width: 1380px) {
      flex: 0 1 20%;
    }
    @media screen and (max-width: 1200px) {
      flex: 0 1 auto;
    }
    @media screen and (max-width: 800px) {
      flex: 0 1 33%;
      margin-bottom: 13px;
    }
    @media screen and (max-width: 500px) {
      flex: 0 1 100%;
    }
  }

  .form_city {
    @media screen and (max-width: 1200px) {
      flex: 0 1 18% !important;
    }
    @media screen and (max-width: 800px) {
      flex: 0 1 30% !important;
      // margin-bottom: 13px;
    }

    @media screen and (max-width: 600px) {
      flex: 0 1 31% !important;
    }
    @media screen and (max-width: 500px) {
      flex: 0 1 48% !important;
    }
  }

  .mini_select {
    flex: 0 1 13%;
    height: 40px;
    min-width: initial;
    div {
      height: 100%;
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .white_input {
      :global {
        .MuiOutlinedInput-root {
          background: #fff;
          input {
            color: $black !important;
            font-size: 0.8rem !important;
            &::placeholder {
              font-size: 14px;
              color: $black !important;
              font-weight: 500 !important;
            }
          }
        }
      }
    }

    :global {
      .select-container {
        width: 100% !important;
      }
      .MuiSelect-select {
        padding-right: 20px !important;
      }

      .MuiFormControl-root {
        width: 100% !important;
      }

      .MuiInputBase-formControl {
        width: 100% !important;
        min-width: initial !important;
      }
    }
    @media screen and (max-width: 1300px) {
      flex: 0 1 17%;
    }
    @media screen and (max-width: 800px) {
      flex: 0 1 30%;
      margin-bottom: 13px;
    }
    @media screen and (max-width: 600px) {
      flex: 0 1 31%;
      margin-bottom: 13px;
    }
    @media screen and (max-width: 500px) {
      flex: 0 1 48%;
    }
  }

  .form_radio_group {
    @media screen and (max-width: 1400px) {
      flex: 0 1 auto;
    }
    @media screen and (max-width: 800px) {
      margin-bottom: 13px;
    }
    @media screen and (max-width: 500px) {
      flex: 0 1 32% !important;
    }
  }

  .form_btn {
    max-width: 88px;
    padding-top: 9px !important;
    min-width: 80px !important;
    padding-bottom: 10px !important;

    @media screen and (max-width: 1400px) {
      flex: 0 1 auto;
    }
  }

  @media screen and (max-width: 600px) {
    display: block;
    margin-bottom: 20px;
  }
}

.form_overflow {
  overflow: hidden;
  max-height: 40px;

  @media screen and (max-width: 600px) {
    max-height: 0px;
  }
}

.group {
  label {
    background: #fff;
  }
}

.visibility_changing {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $green-lite;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 20px;

  p,
  span {
    display: block;
  }

  p {
    svg {
      transform: translateY(-1px) rotate(180deg);
    }
  }

  svg {
    transform: translateY(-1px);

    path {
      stroke: $green;
    }
  }

  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.ageSelect {
  div {
    label {
      transform: translate(14px, 11px) scale(1) !important;
    }
  }
}
.select {
  div {
    label {
      transform: translate(14px, 10px) scale(1) !important;
    }
  }
  max-width: 192px;
  min-width: 198px;
  @media screen and (max-width: 800px) {
    min-width: auto;
    max-width: 24vw;
  }
  @media screen and (max-width: 600px) {
    min-width: auto;
    max-width: 50vw;
  }
  @media screen and (max-width: 500px) {
    min-width: auto;
    max-width: 100%;
  }
}

.form_btn_reset {
  min-height: 40px !important;
  height: 40px !important;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.form_btn_reset_mobile {
  display: none !important;
  min-height: 40px !important;
  height: 40px !important;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    display: inline-flex !important;
  }
}
