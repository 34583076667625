.container {
  display: flex;
  align-items: center;

  .checkbox {
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .checked {
      width: 18px;
      height: 18px;
    }
  }
}
