@import '@/styles/vars.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .noCardsFound {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
  }

  .cardsContainer {
    max-width: 950px;
  }

  :global {
    .MuiMasonry-root {
      width: auto;
    }
  }

  @media only screen and (max-width: 1336px) {
    .cardsContainer {
      max-width: 600px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .cardsContainer {
      max-width: 100%;
      :global {
        .card {
          display: flex;
          justify-content: center !important;
        }
      }
    }
  }

  @media only screen and (max-width: 669px) {
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    :global {
      .MuiBox-root {
        width: 100%;
        .card {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .MuiMasonry-root {
        width: auto;
      }
    }
    .noCardsFound {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 520px) {
    :global {
      .MuiBox-root {
        .card {
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }
  @media only screen and (max-width: 390px) {
    :global {
      .MuiBox-root {
        .card {
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.addAnnouncement {
  @media only screen and (max-width: 650px) {
    min-width: 40px !important;
    padding: 0px !important;
    p {
      display: none;
    }
    :global {
      .MuiButton-startIcon {
        margin: 0px;
      }
    }
  }
}

.announcementsNav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 940px;
  @media only screen and (max-width: 1200px) {
    padding-right: 0px;
    max-width: 100%;
  }
}

.see_more_btn {
  display: flex;
  align-items: center;
  margin: 0 auto;
  transition: all 0.2s linear;
  background: transparent;
  outline: none;
  border: none;

  font-size: 14px;
  line-height: 16px;

  color: $green;
  cursor: pointer;

  svg {
    margin-left: 7px;

    path {
      transition: all 0.2s linear;
    }
  }

  &:hover {
    color: $orange;

    path {
      fill: $orange;
    }
  }
}

.modal {
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    text-align: center;
  }
  .modalButtons {
    display: flex;
    gap: 5px;
  }
  .deleteBtn {
    background-color: #f44336 !important;
  }
}
