.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  button {
    width: 50%;
    align-self: flex-end;
  }
}
