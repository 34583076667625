@import '@styles/vars.scss';

.Modalcontainer {
  max-width: 609px;
  max-height: 592px;
  width: 100%;
  div {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    max-height: 567px;

    .rating {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .ratingProgress {
        max-width: 265px;
        padding: 12px 32px;
        border-radius: 10px;
        background-color: rgb($green-lite, 0.1);
      }
      @media screen and (max-width: 546px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }

    .feedbacks {
      margin-top: 50px;
      padding: 12px 0px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: calc(100% + 20px);
      overflow-y: scroll;

      .feedback {
        padding-right: 34px;
      }
    }
  }
}
