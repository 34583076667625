.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    background-color: white;
    border-radius: 20px;
    padding: 18px 24px;
    max-width: 909px;

    .blogImage {
      border: 1px solid #ccc;
    }

    .titleInput {
      background-color: white;
      border: 1px solid #ccc;
    }
    .rte {
      background-color: white;
      width: 100%;
      max-width: 909px;
      img {
        max-width: 909px;
      }
    }
    .submitButton {
      margin-top: 10px;
      align-self: flex-end;
    }
    .autoComplete {
      width: 50%;
    }
  }

  :global {
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
      content: var(--before-content);
      font-size: var(--before-font-size);
    }
  }
}
