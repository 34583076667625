.radio_group {
  //width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  gap: 8px;
}

.radio {
  display: none !important;
}

.label {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    position: relative;
    z-index: 100;
  }
}

.female_check {
  background: #ff4370 !important;
  svg {
    path {
      fill: #fff !important;
    }
  }
}

.male_check {
  background: #50b3ff !important;
  svg {
    path {
      fill: #fff !important;
    }
  }
}
