.button_logout {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 46px 12px 48px;
  border-radius: 32px;
  gap: 40px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #3a3d42;
  width: 100%;
  @media screen and (max-width: 525px) {
    padding: 12px 27px;
  }
}

.modalLogOut {
  text-align: center;
  div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .cancel {
    cursor: pointer;
    padding: 15px 30px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    background: rgba(128, 156, 129, 0.15);
    border-radius: 32px;
  }
  .logout {
    cursor: pointer;
    padding: 15px 30px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    background: #f16526;
    border-radius: 32px;
  }
}
