@import '@/styles/vars.scss';

.content__rating_text {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  @media screen and (max-width: 768px) {
    justify-content: initial;
    gap: 2px;
    & > span:first-child {
      // display: none;
      &::after {
        content: ' - ';
        position: relative;
        left: -1px;
      }
    }
  }
  /* identical to box height */

  color: $green-lite;
  .rating_text__num {
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 600;
    color: #33a03e;
  }
}
.content__rating_bar {
  margin-top: 4px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  progress {
    border: none;
    overflow: hidden;
  }
  progress::-webkit-progress-bar {
    background: #dcdcdc;
    height: 4px;
    border-radius: 8px;
  }
  progress::-webkit-progress-value {
    border-radius: 8px;
    overflow: hidden;
  }
  progress::-moz-progress-bar {
    /* style rules */
  }
}
