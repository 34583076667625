@import '@styles/vars.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  outline: none;

  &:focus-visible {
    outline: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(128, 156, 129, 0.1);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $green;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb($green, 0.9);
  }
}

.close {
  position: relative;
  top: -20px;
  min-width: 12px;
  min-height: 12px;

  &:hover {
    cursor: pointer;
  }
}

.wrap {
  background-color: white;
  max-width: 100%;
  padding: 25px 32px;
  border-radius: 20px;
  &:focus-visible {
    outline: none;
  }
}
