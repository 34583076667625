.container {
  display: flex;
  gap: 21px;
  align-items: center;
  width: 100% !important;
  :global {
    & .MuiAutocomplete-root {
      display: flex;
      align-items: center;
      border: none;
      min-width: 100%;
      height: 40px;
      background-color: white;
      border-radius: 10px;
    }
    & .MuiFormControl-root {
      min-width: unset;
      width: 100%;
    }
    & .MuiTextField-root {
      display: flex;
      align-items: center;
      & .MuiInputBase-input {
        font-size: 14px;
        font-family: Raleway, sans-serif !important;
        font-weight: 500;
      }
    }
    & .MuiFormLabel-root {
      max-width: 45px;
      width: 100%;
      font-size: 0.8rem;
      font-family: Raleway, sans-serif !important;
    }

    & .icon {
      margin-right: 13px;
      width: 16px;
      height: 16px;
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}
