@import '@styles/vars.scss';

.profile_info {
  display: flex;
  align-items: center;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 72px;
    min-height: 72px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #c6d9c6;
    overflow: hidden;
    border-radius: 50%;

    margin-right: 16px;

    @media screen and (max-width: 475px) {
      min-width: 24px;
      min-height: 24px;
      .photoIcon {
        width: 8px;
        height: 8px;
      }
    }
  }
  &__content {
    flex-grow: 1;
    @media screen and (max-width: 475px) {
      display: flex;
      align-items: baseline;
      gap: 10px;
    }
    .content__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;

      @media screen and (max-width: 475px) {
        font-size: 13px;
        margin-bottom: 0px;
      }
      /* text */
      color: $black;
      .name__num {
        font-feature-settings: 'pnum' on, 'lnum' on;
      }
    }
  }
  &__favorite {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
    color: #809c81;
    gap: 5px;
  }
  &__like {
    height: 15px;
    fill: $orange;
    width: 15px;
    fill-opacity: 1;
    column-fill: auto;

    &:hover {
      stroke: transparent !important;
      fill: $green-lite;
      fill-opacity: 1;
      cursor: pointer;
    }
  }
  &__mobileRating {
    display: none;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 600;
    color: #33a03e;
  }

  @media only screen and (max-width: 600px) {
    &__rating {
      display: none;
    }

    &__mobileRating {
      display: block;
    }

    &__content {
      display: flex;
      gap: 7px;
      align-items: center;
      font-size: 12px;
      .content__name {
        font-size: 12px;
        margin-bottom: 0px;
      }
    }

    &__avatar {
      width: 26px;
      height: 26px;
      padding: 8px;
    }
    font-size: 8px;
  }
  &__favorite {
    margin-top: 0px;
    font-size: 10px;
    p {
      display: none;
    }
  }
  &__like {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}

.profileFavorite_info {
  flex: 1;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  .profile_info__favorite {
    margin-left: auto;
  }
}
