@import '@styles/vars.scss';

.tag {
  padding: 12px 21px !important;
  background: rgba(128, 156, 129, 0.1) !important;
  border-radius: 8px !important;
  height: 100% !important;
  font-family: 'Raleway' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 14px;
  position: relative;
  max-height: 39px;

  .deleteIcon {
    display: none;
  }
}

.enabled {
  background-color: $green !important;
  color: white !important;
  transition: 100ms ease;

  &:hover {
    background-color: $orange !important;
  }

  .deleteIcon {
    display: block;
    width: 6.5px;
    height: 6.5px;
    position: absolute;
    right: 4px;
    top: 8px;
  }
}
