.container {
  max-width: 626px;
  min-height: 429px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;

  .ModalWrap {
    width: 100%;
  }

  .radioGroup {
    display: flex;
    flex-direction: row !important;
    gap: 8px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .desc {
      line-height: 124.4%;
      max-width: 378px;
    }

    .textArea {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .submitWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      @media screen and (max-width: 550px) {
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    .agreement {
      display: flex;
      align-items: center;
      width: 252px;
      gap: 16px;
      @media screen and (max-width: 550px) {
        gap: 22px;
        margin-left: -5px;
      }
    }

    .checkboxes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      max-width: 450px;

      @media screen and (max-width: 360px) {
        grid-template-columns: 1fr;
        margin-left: -5px;
      }
    }
  }

  :global {
    .MuiButton-sizeMedium {
      padding: 12px 33px !important;
    }
  }
}

.error {
  :global {
    .checkbox {
      outline: 1px solid red;
    }
  }
}
