.container {
  background-color: white;
  border-radius: 20px;
  padding: 26px 32px;
  max-width: 452px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 18px;
    justify-content: space-between;
    :global {
      .inputContainer {
        flex-direction: column !important;
        width: 100%;
        max-width: 184px;
        gap: 4px;
        .MuiInputLabel-root {
          text-align: left;
        }
        .MuiFormControl-root {
          width: 100%;
        }
        @media screen and (max-width: 464px) {
          max-width: inherit;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
}

.confirmModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .btns {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: center;
    .deleteBtn {
      background-color: #f44336 !important;
    }
  }
}
