@import '@/styles/vars.scss';

.header {
  display: flex;
  margin-bottom: 15px;
  max-width: 931px;

  .header_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    color: $black;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .header_slogan {
    font-weight: 700;
    font-size: 12px;
    padding: 0 10px;
    outline: none;
    border: none;
    margin-left: 17px;
    height: 22px;

    text-align: center;

    color: #ffffff;

    background: #f16526;
    border-radius: 30px;
    word-break: keep-all;
    white-space: nowrap;
    @media screen and (max-width: 600px) {
      margin-left: 10px;

      font-size: 10px;
      height: 20px;
      margin-bottom: -2px;
    }
  }
}

.add_announcement {
  padding: 4px 21px;
  background: rgba($green-lite, 0.15);
  border: none;
  border-radius: 30px;
  color: $green-lite;
  font-weight: 600;
  font-family: 'Raleway';
  font-size: 12px;
  text-decoration: none;
  align-self: flex-end;
  margin-left: auto;
  @media screen and (max-width: 600px) {
    display: none;
  }
}
