@import '@/styles/vars.scss';

.green_button {
  background: $green !important;
  color: #fff !important;
  padding: 12px 27px !important;
  font-size: 12px !important;
  border-radius: 32px !important;
  min-width: 105px !important;
  text-transform: none !important;
}

.green_button_loading {
  background: $green-lite !important;
  padding: 12px 0px !important;
  font-size: 12px !important;
  border-radius: 32px !important;
  min-width: 115px !important;
  &:hover {
    cursor: default;
  }
}
