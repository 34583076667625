@import '@/styles/vars.scss';

.renew_pro {
  background: #fff;
  padding: 26px 32px;
  border-radius: 20px;
  max-width: 296px;

  &_disabled {
    .renew_pro__price {
      color: #b8b8b8 !important;
    }

    .renew_pro__price {
      color: #b8b8b8 !important;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 133.4%;
    color: #000;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* text */
    color: $black;
    margin-top: 7px;
    margin-bottom: 23px;
  }

  &__price {
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 14px;
    line-height: 133.4%;

    /* identical to box height, or 19px */
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: $orange;
  }

  &__btn {
    background: $orange !important;
    padding-left: 47px !important;
    padding-right: 47px !important;
  }
}

.subscriptionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.see_more_btn {
  padding-top: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  transition: all 0.2s linear;
  background: transparent;
  outline: none;
  border: none;

  font-size: 14px;
  line-height: 16px;

  color: $green;
  cursor: pointer;

  svg {
    margin-left: 7px;

    path {
      transition: all 0.2s linear;
    }
  }

  &:hover {
    color: $orange;

    path {
      fill: $orange;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: center;
  :global {
    .MuiPaginationItem-root {
      background-color: rgba($green, 0.6);
      color: white;
    }
    .Mui-selected {
      background-color: rgba($green, 1);
    }

    .MuiPaginationItem-previousNext {
      background: none;
      color: $green;
    }
  }
}

a {
  color: $green;
}

@media only screen and (max-width: 414px) {
  .pagination {
    :global {
      .MuiPaginationItem-root {
        min-width: 25px;
        height: 24px;
        font-size: 10px;
      }
    }
  }
}
