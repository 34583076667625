.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  font-weight: 600;

  .successIMG {
    width: 80px;
    height: 80px;
  }

  .addNewBtn {
    width: 208px;
    display: flex;
    gap: 8px;
  }
}
