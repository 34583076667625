@import '@/styles/vars.scss';

.notifications_item {
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  padding: 19px 24px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 9px;
    .header__title {
      flex: 0 1 85%;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;

      color: $black;
    }
    .header__date {
      text-align: right;
      flex: 0 1 15%;
      font-family: 'Mulish', sans-serif;
      font-size: 12px;
      line-height: 15px;

      color: $green-lite;
      white-space: pre;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: $green-lite;
    max-width: 624px;
  }

  .orange_ellipse {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media only screen and (max-width: 467px) {
    &__header {
      .header__title {
        font-size: 13px;
      }
      .header__date {
        font-size: 11px;
      }
    }
    &__text {
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 397px) {
    &__header {
      .header__title {
        font-size: 12px;
      }
      .header__date {
        font-size: 12px;
      }
    }
    &__text {
      font-size: 12px;
    }
  }
}
