.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layout_content {
  display: flex;
  gap: 32px;
  align-items: flex-start;
  padding-bottom: 88px;
  justify-content: space-between;

  .leftCol {
    flex: 0 1 21%;
    position: sticky;
    top: 30px;
    bottom: 0;
    height: 883px;
    @media only screen and (min-width: 1200px) {
      height: initial;
    }
  }
  .rightCol {
    flex: 0 1 73%;

    @media only screen and (min-width: 1200px) {
      min-width: 1045px;
    }

    @media only screen and (min-width: 560px) and (max-width: 768px) {
      flex: initial;
    }
  }
  .rightColSettings {
    flex: 0 1 37.7%;
    max-width: 504px;
  }
  .settings_nav {
    max-width: 400px;
    flex: 0 1 35.9%;
    padding-top: 38px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (max-width: 896px) {
      display: none !important;
    }
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;

    .leftCol {
      position: relative;
      top: 0;
      flex: 0 1 100%;
      height: auto;
    }

    .rightCol {
      flex: 0 1 100%;
      max-width: 100%;
    }
    .rightColSettings {
      flex: 0 1 100%;
      @media screen and (max-width: 550px) {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .contentCol {
    max-width: 100%;
  }
}
