.container {
  display: flex;
  gap: 21px;
  align-items: center;
  width: 100% !important;
  .label {
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px;
    max-width: 100%;
    width: 100%;
    text-align: end;
    flex: 1 1;
    white-space: pre-wrap !important;
    overflow: visible !important;
    &_fs14 {
      font-size: 14px !important;
    }
  }

  :global {
    .MuiInputBase-root {
      background-color: white !important;
      font-weight: 600 !important;
      border-radius: 10px !important;
      width: 100% !important;
      max-height: 40px !important;
      min-width: 155px !important;
    }

    & .MuiFormControl-root {
      flex: 2 1;
    }

    & .MuiSelect-select {
      font-family: Raleway, sans-serif;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .arrow {
      margin-right: 18px;
    }
    & .Mui-selected {
      background-color: transparent;
      color: none;
    }
    & .MuiMenuItem-gutters {
      font-size: 0.8rem;
      font-family: 'Raleway, sans-serif' !important;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      transition: 300ms ease;
      &:hover {
        background-color: #f3f5f3;
        & .circle {
          opacity: 1;
        }
      }
    }
    & .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      border-radius: 8px;
      font-size: 0.8rem;
    }
    & .MuiInputLabel-root {
      // transform: translate(14px, 11px) scale(1) !important;
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #3A3D42;
    }
    & .Mui-focused {
      color: black !important;
    }

    & .MuiFormLabel-filled {
      display: none;
    }
  }
}
