.container {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 20px;

  .imageContainer {
    border: 1px solid #c6d9c6;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
  }

  .image {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    object-fit: cover;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .info {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .show {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}
