@import '@/styles/vars.scss';
.footer_wrapper {
  display: flex;
  flex-direction: column;
  gap: 9px;
  .footer {
    padding: 12px 24px;
    background: #fff;
    border-radius: 8px;
    max-width: 1306px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-self: flex-end;
    justify-items: flex-end;
    .footer__copyright {
      .left__num {
        font-family: 'Montserrat', sans-serif;
        margin-right: 6px;
      }

      span {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #8d8d8d;
      }

      a {
        margin-left: 34px;
        font-weight: 500 !important;
        font-size: 11px;
        line-height: 14px;
        color: #000;
        text-decoration: none;
        transition: all 0.1s linear;

        &:hover {
          color: $orange;
        }
      }

      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        a {
          color: #33a33f;
          margin: 0;
        }
      }
    }

    .footer_link {
      margin-left: 34px;
      font-weight: 500 !important;
      font-size: 11px;
      line-height: 14px;
      color: #000;
      text-decoration: none;
      transition: all 0.1s linear;

      &:hover {
        color: $orange;
      }
      @media screen and (max-width: 1100px) {
        margin-left: 24px;
      }
      @media screen and (max-width: 1000px) {
        margin-left: 12px;
      }
    }

    @media screen and (max-width: 900px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 600px) {
      padding: 10px;

      a,
      span {
        font-size: 10px !important;
      }
    }
    @media screen and (max-width: 500px) {
      padding: 8px;
      a,
      span {
        font-size: 10px !important;
      }

      .footer_link {
        margin-left: 8px;
      }
    }
    @media screen and (max-width: 400px) {
      padding: 8px;
      a,
      span {
        font-size: 8px !important;
        line-height: 10px !important;
      }

      .footer_link {
        margin-left: 8px;
        line-height: 10px;
      }

      .footer__menu {
        margin-top: -5px;
      }
    }

    @media screen and (max-width: 340px) {
      .footer_link {
        margin-left: 3px;
      }
    }
  }

  .footer_bottom {
    max-width: 1306px;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-items: flex-end;
    justify-content: space-between;
    gap: 30px;
    padding: 0px 24px;
    @media screen and (max-width: 600px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 500px) {
      padding: 0 8px;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    img {
      width: 170px;
    }

    &__wrap {
      display: flex;
      gap: 60px;
      @media screen and (max-width: 950px) {
        flex-direction: column;
        gap: 10px;
      }
      a {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.5);
        text-decoration: none;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 3px;
      p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.modal {
  text-align: center;
  max-width: 550px;
}

.link {
  cursor: pointer;
}
