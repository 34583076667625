@import '@styles/vars.scss';

.card {
  width: 296px;
  min-width: 296px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__title {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__imageLink {
    max-height: 144px;
  }

  &__image {
    max-height: 144px;
    max-width: 296px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__info {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 18px 24px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: white;
  }

  &__titleContainer {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 132.9%;
    text-decoration: none;
    color: #3a3d42;
    position: relative;
    float: left;
  }

  &__desc {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3a3d42;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    display: -webkit-box;
    line-clamp: 3;
    box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-word;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
  }

  &__like {
    fill: #809c81;

    &:hover {
      fill: $orange;
      cursor: pointer;
    }
  }

  &__liked {
    fill: $orange;
  }

  &__link {
    text-decoration: none !important;
  }

  &__messageSVG {
    display: none;
  }

  &__extraButtons {
    &__pen {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 168px;
    min-width: 168px;

    &__like {
      width: 22px;
      height: 20px;
    }

    &__button {
      display: none !important;
    }

    &__messageSVG {
      display: block;
      background-color: $green;
      border-radius: 50%;
      padding: 7px 0px;
      width: 30px;
      height: 30px;
      path {
        stroke: white;
      }
    }

    &__title {
      font-size: 14px;
      word-break: break-word;
    }

    &__link__message {
      display: none;
    }
    &__profile {
      :global {
        .avatar {
          display: none !important;
        }

        .info {
          font-size: 12px;
          span {
            font-size: 12px;
          }
        }
        .rating {
          font-size: 12px;

          margin-left: auto;
        }
      }
    }

    &__desc {
      display: none;
    }

    &__info {
      padding: 14px 15px;
    }

    &__image {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      max-height: 81px;
    }

    &__info {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    &__buttons {
      min-height: 25px;
    }
    &__extraButtons {
      min-width: 20px;
      height: 20px !important;
      padding: 0px !important;
      background: none !important;
      p {
        display: none;
      }
      &__pen {
        display: block;
      }
    }
  }
}

.TOP {
  background-color: $orange;
  color: white;
  position: absolute;
  padding: 7px 11px;
  top: 18px;
  right: -7px;
  width: 48px;
  height: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    height: 2.7px;
    content: '';
    position: absolute;
    left: -5px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 5px solid $orange;
  }
  &::after {
    content: '';
    position: absolute;
    right: -0.3px;
    bottom: -8px;
    border-top: 0px solid transparent;
    border-bottom: 8.1px solid transparent;
    border-left: 7.5px solid #a93a08;
    z-index: 1;
  }
  @media only screen and (max-width: 600px) {
    padding: 5px 8px;
    right: -7px;
  }
}

.topCardWithImage {
  .card__imageLink {
    position: relative;
  }
  .TOP {
    @extend .TOP;
  }
}

.topCardWithoutImage {
  .card__imageLink {
    display: none;
  }
  .TOP {
    @extend .TOP;
    position: relative;
    float: right;
    right: -32px;
    top: 0px;
    @media only screen and (max-width: 600px) {
      padding: 5px 8px;
      right: -23px;
    }
  }
}

.highlightedCard {
  border: 3px solid rgba(51, 163, 63, 0.65);
  border-radius: 20px;
}

.noImage {
  border-radius: 20px;
}

.modal {
  text-align: center;
  max-width: 500px;

  .link {
    cursor: pointer;
  }
}
