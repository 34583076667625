@import '@styles/vars.scss';

.container {
  display: flex;
  gap: 16px;
  font-weight: 500;
  font-feature-settings: 'pnum' on, 'lnum' on;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 32px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #c6d9c6;
    overflow: hidden;
    border-radius: 50%;
    .photoIcon {
      width: 10px;
      height: 10px;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .details {
    display: flex;
    justify-content: space-between;
    color: $green-lite;
    font-size: 12px;
  }
  .ratingAndName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rating {
      flex-direction: row-reverse;
      :global {
        .MuiSvgIcon-root {
          width: 10px;
          fill: $green;
        }
        .MuiRating-iconEmpty {
          display: none;
        }
      }
    }
  }

  .desc {
    font-size: 12px;
  }

  .location {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
