@import '@/styles/vars.scss';

.white_container {
  padding: 25px 32px;
  background: #ffffff;
  border-radius: 20px;
}

.form {
  text-align: center;

  .form_input {
    width: 100%;
    margin-bottom: 6px;

    input {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .form_pass_input {
    input {
      -webkit-text-security: disc;
    }
  }

  .form__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 124.4%;
    text-align: center;
    margin-bottom: 24px;
    color: $black;

    &_m0 {
      margin-bottom: 0;
    }
  }

  .form_city {
    :global {
      .MuiAutocomplete-root {
        height: 43px !important;
      }
    }
    input {
      font-size: 12px !important;
      color: #809c81 !important;
      font-weight: 600 !important;

      &::placeholder {
        color: #809c81 !important;
        font-weight: 600 !important;
        opacity: 1 !important;
      }
    }
  }
}

.form_title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;

  color: $black;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.form_btn {
  display: block;
  margin-top: 10px !important;
}

.controls {
  margin-top: 17px;
  font-weight: 500;
  font-size: 12px;
  line-height: 134.9%;
  text-align: center;

  color: $black;

  &__item {
    margin-bottom: 3px;

    &_clickable {
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        color: $orange;
      }
    }
  }
}

.green {
  color: $green;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    color: $orange;
  }
}

.green2 {
  color: $green2;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    color: $orange;
  }
}

.forgot_pass {
  width: 100%;
  max-width: 217px;
}

.modal_form_container {
  width: 100%;
  max-width: 217px;
}

.select_bg {
  background: rgba($green-lite, 0.1);
}

.form_radio_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .group_span {
    margin-left: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    /* identical to box height */

    color: $green-lite;
  }
}

.form_city {
  display: flex;

  .search_input {
    flex: 100%;
    margin-top: 2px;
    margin-bottom: 2px;

    :global {
      .MuiAutocomplete-root {
        width: auto !important;
        background: rgba($green-lite, 0.1) !important;
      }
    }
  }
}

.changing_pass {
  max-width: 280px;
}
