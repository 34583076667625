@import '@styles/vars.scss';

.container {
  background-color: white !important;
  padding: 41px 68px !important;
  box-shadow: none !important;
  // width: 160px;
  height: 104px;
  overflow: hidden;
  // @media screen and (max-width: 475px) {
  //   width: 217px !important;
  // }
  // @media screen and (max-width: 466px) {
  //   width: 210px !important;
  // }
  // @media screen and (max-width: 456px) {
  //   width: 200px !important;
  // }
  // @media screen and (max-width: 434px) {
  //   width: 193px !important;
  // }
  // @media screen and (max-width: 415px) {
  //   width: 184px !important;
  // }
  // @media screen and (max-width: 400px) {
  //   width: 167px !important;
  // }
  // @media screen and (max-width: 385px) {
  //   width: 170px !important;
  // }
  // @media screen and (max-width: 368px) {
  //   width: 160px !important;
  // }
  // @media screen and (max-width: 355px) {
  //   width: 150px !important;
  // }
  // @media screen and (max-width: 333px) {
  //   width: 142px !important;
  // }
  .uploadedImage {
    width: 160px;
    height: 104px;
    @media screen and (max-width: 475px) {
      width: 217px !important;
    }
    @media screen and (max-width: 466px) {
      width: 210px !important;
    }
    @media screen and (max-width: 456px) {
      width: 200px !important;
    }
    @media screen and (max-width: 434px) {
      width: 193px !important;
    }
    @media screen and (max-width: 415px) {
      width: 184px !important;
    }
    @media screen and (max-width: 400px) {
      width: 167px !important;
    }
    @media screen and (max-width: 385px) {
      width: 170px !important;
    }
    @media screen and (max-width: 368px) {
      width: 160px !important;
    }
    @media screen and (max-width: 355px) {
      width: 150px !important;
    }
    @media screen and (max-width: 333px) {
      width: 142px !important;
    }
    img {
      object-fit: contain;
      @media screen and (min-width: 475px) {
        width: 170px;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.uploaded {
  padding: 0px !important;
  .cameraIcon {
    visibility: hidden;
    display: none;
    width: 100%;
    height: 100%;
  }
}
