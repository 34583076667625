@import '@/styles/vars.scss';

.buy_pro {
  max-width: 816px;
  background: #fff;
  border-radius: 20px;
  padding: 28px 14px;
  padding-right: 38px;

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 540px) {
    flex-direction: column;
  }

  &__image {
    margin-right: 22px;
    img {
      @media only screen and (max-width: 540px) {
        width: 96px;
      }
    }
  }

  &__info {
    @media only screen and (max-width: 540px) {
      margin-left: 15px;
    }
    flex-grow: 1;
    .info__title {
      max-width: 303px;
      font-weight: 700;
      font-size: 20px;
      line-height: 133.4%;
      color: $black;
      margin-bottom: 11px;

      span {
        color: $orange;
      }
    }

    .info__list {
      margin-top: 11px;
      margin-left: 15px;
      margin-bottom: 28px;
      max-width: 480px;
      .list__item {
        font-size: 8px;
      }
    }
    .list__text {
      font-weight: 400;
      font-size: 14px !important;
      line-height: 18px;
      color: $black;
      // margin-left: 10px;

      &_p {
        margin-bottom: 8px;
      }
    }

    .info__price {
      font-weight: 700;
      font-size: 14px;
      line-height: 133.4%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $orange;
      margin-bottom: 9px;
    }

    .info__btn {
      background: $orange !important;
      padding-left: 39px !important;
      padding-right: 39px !important;
    }
  }
}
