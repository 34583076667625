.confirmModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .btns {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: center;
  }
}
.table {
  max-width: 1100px;
  :global {
    .MuiTable-root {
      width: 100%;
      display: block;
      overflow-x: auto;
    }
  }
}
