@import '@styles/vars.scss';

.otherUserInfo {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  background-color: white;

  .goBackContainer {
    display: flex;
    align-items: center;
    gap: 9px;
    color: $green;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
  }

  .username {
    font-weight: 700;
    font-size: 14px;
  }

  .goBackArrow {
    rotate: 90deg;
    width: 9px;
    height: 8px;
    path {
      stroke: $green;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid $green-lite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media only screen and (max-width: 587px) {
    display: flex;
  }
}
