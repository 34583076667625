@import '@styles/vars.scss';

.switch {
  :global {
    padding: 8px !important;
    & .MuiSwitch-track {
      background: rgba(128, 156, 129, 0.3) !important;
      border-radius: 11px !important;
    }
    & .MuiSwitch-thumb {
      color: $green-lite !important;
      box-shadow: none !important;
      width: 16px !important;
      height: 16px !important;
      margin: 2px !important;
    }
    & .Mui-checked {
      color: $green !important;
    }
    & .Mui-checked .MuiSwitch-thumb {
      color: $green !important;
    }
    & .Mui-checked + .MuiSwitch-track {
      background-color: #33a03e96 !important;
    }
  }
}

.switchContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  .switchWrap {
    display: flex;
    gap: 15px;
  }

  & .switchGroup {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 32px;
    gap: 24px;
  }

  & .switchDetails {
    width: 100%;
    word-wrap: break-word;
    flex-grow: 1;
  }

  & .switchContainer {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  & .switchTitle {
    font-size: 14px;
    font-weight: 700;
    
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
  }
  & .switchDesc {
    font-size: 12px;
    color: $green-lite;
  }

  & .switchLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $green;
    font-weight: 600;
    font-feature-settings: 'pnum' on, 'lnum' on;
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
  }

  :global {
    .MuiFormControl-root {
      min-width: fit-content !important;
    }
  }
}
