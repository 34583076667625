@import '@styles/vars.scss';
.formWrapper {
  flex-direction: column;
  gap: 8px;
  height: 100%;
  max-width: 504px;
}

.uploadContainer {
  display: flex;
  gap: 8px;
  border-radius: 20px;
  overflow: hidden;
  justify-content: space-between;
  @media only screen and (max-width: 531px) {
    overflow-x: auto !important;
    :global {
      .swiper {
        width: 100%;
      }
      .MuiButtonBase-root {
        min-width: 165px;
      }
      .MuiFormControl-root {
        min-width: 165px;
      }
    }
  }
}

.formInputBox {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 40px;
  border-radius: 20px;
  gap: 8px;
  .greenInput {
    max-width: 320px !important;
    gap: 26px;
  }

  @media only screen and (max-width: 531px) {
    border-radius: 10px;
    padding: 24px 20px;
    p {
      font-size: 12.5px !important;
    }
    label {
      font-size: 12.5px !important;
    }
  }
  @media only screen and (max-width: 430px) {
    p {
      font-size: 11.5px !important;
    }
    label {
      font-size: 11.5px;
    }
  }
}

.categories {
  text-align: right;
}

.labelTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 128.9%;
}

.labelDesc {
  font-weight: 400;
  font-size: 12px;
  line-height: 128.9%;
  color: $green-lite;
}

.budgetInput {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 70px;
}

.locationBox {
  .smallLabel {
    max-width: 320px;
  }

  :global {
    & .MuiInputLabel-root {
      flex: none !important;
      width: fit-content;
      font-size: 14px;
      max-width: 50px;
      color: #000000;
    }
    .inputContainer,
    .autoComplete-container {
      gap: 28px;
    }
  }
}

.submitContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;

  .total {
    font-weight: 600;
    font-size: 15px;
    line-height: 128.9%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .publish {
    margin-left: auto !important;
  }

  .green {
    color: $green;
  }

  :global {
    .MuiButtonBase-root {
      width: 200px;
      height: 40px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      white-space: pre !important;
      line-height: 15px;
    }
  }
  @media only screen and (max-width: 531px) {
    .total {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 430px) {
    .total {
      font-size: 11px;
    }
    :global {
      .MuiButtonBase-root {
        width: 140px;
        font-size: 11px !important;
      }
    }
  }
}

.map {
  min-height: 232px;
}