@import '@styles/vars.scss';

.wrapper {
  max-height: 100%;
  width: 70%;
  background: #c6d9c6 47%;
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey;

  &_dialogWindow {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 18px;
    overflow: auto;
    height: 600px;

    .chooseDialog {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .sendMessageContainer {
    flex: 1;
    &_files {
      display: flex;
      flex-direction: column;
    }

    &_unblock {
      &:hover {
        background-color: rgba($white, 0.6);
        cursor: pointer;
      }
    }

    &_unblockText {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #d2584d;
      font-weight: 600;
    }

    &_sendMessage,
    &_unblock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0px 0px 20px 0px;
      gap: 10px;
      min-height: 54px;
      background-color: $white;
      padding: 0 15px;

      &_sendButton {
        border: none;
        background: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  :global {
    .MuiFormControl-root {
      width: 100% !important;
      .MuiInputBase-root {
        width: 100% !important;
        background-color: $white;
      }
      .MuiInputBase-input {
        min-height: 0px !important;
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.65) 0%,
      rgba(255, 255, 255, 0.377) 100%
    );
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $green;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb($green, 0.9);
  }

  @media only screen and (max-width: 587px) {
    width: 100%;
  }
}

.notInDialog {
  @media only screen and (max-width: 587px) {
    display: none;
  }
}
