@import '@styles/vars.scss';

.container {
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .buttons {
      align-self: flex-end;

      @media screen and (max-width: 896px) {
        align-self: initial;
      }
    }
  }
  .tabletTitle {
    display: none;
    @media screen and (max-width: 896px) {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 128.9%;
      color: $green-lite;
      margin-bottom: 4px;
    }
  }

  .switchGroup {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 32px;
    gap: 24px;
  }

  .buttons {
    .isTabletBtn {
      display: none;
      @media screen and (max-width: 896px) {
        display: block;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 525px) {
      flex-direction: column;
      gap: 32px;
      align-items: flex-start;
      :global {
        .MuiButtonBase-root {
          align-self: flex-start !important;
        }
      }
    }
  }

  .switchDetails {
    width: 100%;
  }

  .switchContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .switchTitle {
    font-size: 14px;
    font-weight: 700;
  }
  .switchDesc {
    font-size: 12px;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #3a3d42;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 124.4%;
    text-align: center;
    color: #3a3d42;
  }
  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
}
