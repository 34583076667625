@import '@styles/vars.scss';

.container {
  display: flex;

  .label {
    background: rgba(255, 255, 255, 0.6);
    padding: 10px 26px;
    border-radius: 20px;
    color: $green-lite !important;
    font-weight: 600;
    font-size: 14px;
    transition: 300ms ease;

    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .input {
    display: none;
  }

  :global {
    & .Mui-checked + label {
      background: white;
    }
  }
}
