@import '@styles/vars.scss';

.starsRating {
  display: flex;
  gap: 4px;
  width: max-content;
  transition: 200ms ease;

  .star {
    width: 43px;
    height: 43px;
  }

  .selectedStar {
    color: $green;
    path {
      fill-opacity: 1;
    }
  }

  .emptyStar {
    color: $green;
  }

  :global {
    .MuiRating-iconHover {
      transform: scale(1);
    }
  }
}
