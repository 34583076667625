@import '@/styles/vars.scss';

.main {
  &:global(.MuiButton-root) {
    background: $green;
    border-radius: 20px;
    color: $white;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: 40px;
    padding: 0 19px;
    text-transform: none;

    &:hover {
      background: $green;
    }
  }
}

.add {
  composes: main;

  :global(.MuiButton-startIcon) {
    margin-right: 12px;
  }
}

.delete {
  &:global(.MuiButton-root) {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
    color: $green-lite;

    :global(.MuiButton-startIcon) {
      margin-right: 7px;
    }

    &:hover {
      background: none;
      color: $orange;

      :global(.MuiButton-startIcon) path {
        fill: $orange;
      }
    }
  }
}

.info {
  &:global(.MuiButton-root) {
    color: $green-lite;
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;

    :global(.MuiButton-startIcon) {
      margin-right: 7px;
    }

    &:hover {
      background: none;
    }
  }
}

.switch {
  &:global(.MuiButton-root) {
    background: rgba($white, 0.6);
    border-radius: 20px;
    color: $green-lite;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    padding: 0 22px;
    text-transform: none;

    &:hover {
      background: $white;
    }
  }
}

.switchActive {
  &:global(.MuiButton-root) {
    background: $white;
  }
}
