@import '@/styles/vars.scss';

.nav_menu {
  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
}

.short_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s linear;

  &__item {
    &_heart {
      svg {
        path {
          fill: rgb(128, 156, 129);

          fill-opacity: 1;
          column-fill: auto;
        }
      }

      &:hover {
        svg {
          path {
            stroke: transparent !important;
            fill: $green;
            fill-opacity: 1;
          }
        }
      }
    }

    &:hover {
      svg {
        path,
        rect {
          stroke: $green;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.detail_menu {
  &__item {
    &_active {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item__link {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        height: 31px;
        text-decoration: none;
        transition: all 0.1s linear;

        .link__image {
          min-width: 19px;

          margin-right: 10px;

          svg {
            max-width: 16px;
          }
        }

        .link_fav {
          svg {
            path {
              fill: rgb(128, 156, 129);

              fill-opacity: 1;
              column-fill: auto;
            }
          }
        }

        .link__text {
          font-weight: 500;
          font-size: 14px;
          line-height: 128.9%;

          color: $green-lite;
        }
        .link__text {
          color: $green !important;
          filter: contrast(1);
        }

        svg {
          path,
          rect {
            stroke: $green;
          }
        }

        .link_fav {
          svg {
            path {
              stroke: transparent !important;
              fill: $green;
              fill-opacity: 1;
            }
          }
        }
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item__link {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      height: 31px;
      text-decoration: none;
      transition: all 0.1s linear;

      .link__image {
        min-width: 19px;

        margin-right: 10px;

        svg {
          max-width: 16px;
        }
      }

      .link_fav {
        svg {
          path {
            fill: rgb(128, 156, 129);

            fill-opacity: 1;
            column-fill: auto;
          }
        }
      }

      .link__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 128.9%;

        color: $green-lite;
      }
      &_active,
      &:hover {
        .link__text {
          color: $green !important;
          filter: contrast(1);
        }

        svg {
          path,
          rect {
            stroke: $green;
          }
        }

        .link_fav {
          svg {
            path {
              stroke: transparent !important;
              fill: $green;
              fill-opacity: 1;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    order: 2;
    padding-left: 3px;
    margin-top: 10px;
  }
}

.menu_hamburger {
  margin-top: 12px;
  text-align: center;

  &__btn {
    background: #fff;
    border: none;
    cursor: pointer;
    svg {
      path,
      rect {
        transition: all 0.1s linear;
      }
      &:hover {
        path,
        rect {
          fill: $green;
          fill-opacity: 1;
        }
      }
    }
  }

  .sider_tablet_menu__info {
    display: none;
  }

  @media screen and (max-width: 450px) {
    order: 1;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;

    .sider_tablet_menu__info {
      display: flex;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;

      .info_avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid #c6d9c6;
        overflow: hidden;
        border-radius: 50%;

        margin-right: 16px;
      }
    }
  }
}

.notifications {
  background: $orange;
  color: #fff;
  padding: 0px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 128.9%;
  background: #f16526;
  border-radius: 17px;
}

.logout {
  font-family: Raleway, sans-serif !important;
  font-weight: 500 !important;
  padding: 0px !important;
  gap: 0px;
  justify-content: flex-start !important;
}
