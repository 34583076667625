.finalContainer {
  min-width: 504px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .payments {
    display: flex;
    gap: 16px;
    flex-direction: column;
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 32px;
    
    @media screen and (max-width: 430px) {
      padding: 26px 16px;
    }
  }

  .submitContainer {
    padding-left: 0px;
    padding-top: 0px;

    @media screen and (max-width: 430px) {
      button{
        min-width: 168px !important;
      }
    }
  }

  @media screen and (max-width: 550px) {
    min-width: initial;
  }
}
