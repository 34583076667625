@import '@styles/vars.scss';

.card {
  width: 296px;
  min-width: 296px;
  display: flex;
  flex-direction: column;

  &__imageLink {
    position: relative;
    max-height: 192px;
    
    .card__views{
      position: absolute;
      left: 16px;
      bottom: 8px;
      background: #fff;
      padding: 4px 15px;
      border-radius: 20px;

      display: flex;
      align-items: center;
      gap: 4px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: 11px;
      font-weight: 500;
      color: $green-lite;
      margin-bottom: -1px;

      @media screen and (max-width: 600px) {
        left: 8px;
        bottom: 8px;
        padding: 4px 8px;
      }
    }
  }

  &__image {
    max-height: 192px;
    max-width: 296px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__info {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 18px 24px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: white;
  }

  &__title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 132.9%;
    text-decoration: none;
    color: #3a3d42;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
  }

  &__like {
    fill: #809c81;

    &:hover {
      fill: $orange;
      cursor: pointer;
    }
  }

  &__liked {
    fill: $orange;
  }

  &__link {
    text-decoration: none !important;
  }

  &__messageSVG {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    width: 168px;
    min-width: 168px;

    &__button {
      display: none !important;
    }

    &__messageSVG {
      display: block;
      background-color: $green;
      border-radius: 50%;
      padding: 7px 0px;
      width: 30px;
      height: 30px;
      path {
        stroke: white;
      }
    }

    &__title {
      font-size: 14px;
      word-break: break-word;
    }

    &__info {
      padding: 5px 9px;
    }

    &__image {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: -5px;
    }

    &__info {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  @media only screen and (max-width: 500px) {
    &__title {
      font-size: 12px;
      word-break: break-word;
    }
  }

}

.noImage {
  border-radius: 20px;
}
