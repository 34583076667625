@import "@styles/vars.scss";

.GoBackTitle {
    color: $green-lite;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 600;
    
    .arrow {
        width: 8px;
        height: 8px;
        transform: rotate(90deg);

        path {
            stroke: $green;
        }
    }

    @media only screen and (max-width: 600px) {
        font-size: 12px;
    }
}