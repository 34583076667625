@import '@styles/vars.scss';
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 21px;
  height: 100%;

  .tagInputContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 11px 21px;
    background-color: rgba($green-lite, 0.1);
    border-radius: 8px;
  }

  .textInputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    // flex: 1;
    min-width: 170px;
    .textInput {
      display: flex;
      background-color: transparent;
      outline: none !important;
      border: none;
      width: 100%;
      height: 100%;

      &::placeholder {
        color: $green-lite;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .inputTag {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  .tagsContainer {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .inputTags {
    background: rgba($green-lite, 0.1);
    border-radius: 8px;
    max-width: 100% !important;
    min-height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .insideTags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    :global {
      .MuiButtonBase-root {
        padding: 5px !important;
      }
    }
  }
}
