.container {
  width: 301px;
  text-align: center;

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-weight: 700;

    .desc {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 12px;
    }
  }
}
