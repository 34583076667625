@import '@/styles/vars.scss';

.blog {
  .blog_themes {
    display: flex;
    align-items: center;
    gap: 16px;
    
    
    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      grid-gap: 10px;
    }
  }

  .cards {
    margin-top: 16px;

    :global {
      .card {
        @media only screen and (max-width: 1000px) {
          max-width: 48%;

          .cardContainer,
          a,
          img {
            width: 100% !important;
            max-width: 100%;
          }
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      max-width: 100% !important;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 100% !important;
    }
  }
}

.see_more_btn {
  display: flex;
  align-items: center;
  margin: 0 auto;
  transition: all 0.2s linear;
  background: transparent;
  outline: none;
  border: none;

  font-size: 14px;
  line-height: 16px;

  color: $green;
  cursor: pointer;

  svg {
    margin-left: 7px;

    path {
      transition: all 0.2s linear;
    }
  }

  &:hover {
    color: $orange;

    path {
      fill: $orange;
    }
  }
}
