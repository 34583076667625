@import '@styles/vars.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .infoContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 25px 24px;
    border-radius: 20px;
  }

  .mainContainer {
    display: flex;
    gap: 16px;
    @media screen and (max-width: 475px) {
      flex-direction: column;
    }
  }

  .infoContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 417px;
    list-style: none;
    .infoDetail {
      display: flex;
      gap: 10px;
      font-size: 14px;

      .infoTitle {
        flex: 1;
        color: $green;
        font-weight: 600;
      }
      .infoStatus {
        flex: 1;
      }
    }
  }

  .tagsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__list {
      pointer-events: none;
    }
  }

  .otherCards {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
  }
  @media only screen and (max-width: 600px) {
    .infoButton {
      font-size: 8px;
    }

    .otherCards {
      max-width: 400px;
      font-size: 10px;
    }

    .infoContainer {
      padding: 14px 16px;
      gap: 10px;
      .infoDetail {
        font-size: 10px;
      }
    }
  }
}

.modalWrap {
  max-width: 400px;
  text-align: center;

  .modalText {
    text-align: center;
    font-size: 16px;
  }

  .modalLink {
    color: $green;
  }
}
