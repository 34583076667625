@import '@/styles/vars.scss';

.notifications {
  &__controlls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 17px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    :global {
      .MuiPaginationItem-root {
        background-color: rgba($green, 0.6);
        color: white;
      }
      .Mui-selected {
        background-color: rgba($green, 1);
      }

      .MuiPaginationItem-previousNext {
        background: none;
        color: $green;
      }
    }
  }

  a {
    color: $green;
  }

  @media only screen and (max-width: 414px) {
    .pagination {
      :global {
        .MuiPaginationItem-root {
          min-width: 25px;
          height: 24px;
          font-size: 10px;
        }
      }
    }
  }
}
