.cardPreview {
  top: 100px;
  position: sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 26px;
  max-width: 296px;
}

.cardPreviewTitle {
  text-align: center;
  max-width: 216px;
}
