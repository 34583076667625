@import '@styles/vars.scss';

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: $white;
  font-size: 13px;
  border: 1px solid rgba($green-lite, 0.2);
  &_fileInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 13px;
      height: 13px;
      fill: $green-lite;
    }
  }

  .trash {
    &:hover {
      cursor: pointer;
    }
  }
}
