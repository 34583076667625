@import '@styles/vars.scss';

.dialogsList {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 34%;
  min-width: 254px;
  background-color: #fff;
  padding-top: 20px;
  max-height: 100%;
  border-radius: 20px 0px 0px 20px;

  .chooseDialog {
    display: none;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 587px) {
    width: 100%;
    .chooseDialog {
      display: flex;
    }
  }
}
.dialogsList::-webkit-scrollbar {
  width: 7px;
}

.dialogsList::-webkit-scrollbar-track {
  background-color: #fff;
}

.dialogsList::-webkit-scrollbar-thumb {
  background-color: $green;
  border-radius: 9em;
  width: 5px;
}

.inDialogWithUserWrapper {
  @media only screen and (max-width: 587px) {
    display: none;
  }
}
