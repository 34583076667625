@import '@styles/vars.scss';

.dialog {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  padding: 5px 16px;

  &_avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 1px solid $green-lite;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &_center {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    h4 {
      color: black;
      font-size: 14px;
    }
    span {
      color: $green-lite;
      font-size: 12px;
      text-overflow: ellipsis;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
    }

    @media only screen and (max-width: 1240px) {
      span {
        max-width: 100px;
      }
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 40px;
    gap: 8px;

    span {
      font-weight: 400;
      font-size: 12px;
      color: $green-lite;
    }

    &_counter {
      min-width: 24px;
      height: 12px;
      background: #f16526;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 600;
        font-size: 10px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $white;
      }
    }
  }
}

.selected {
  background-color: $grey;
}

.link {
  text-decoration: none;
}
