@import '@styles/vars.scss';

.tabletTitle {
  display: none;
  @media screen and (max-width: 820px) {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 128.9%;
    color: $green-lite;
    margin-bottom: 4px;
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  @media screen and (max-width: 768px) {
    margin-bottom: 64px;
  }

  .uploadContainer {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    gap: 8px;
    overflow-x: auto;
  }

  .inputsContainer,
  .tagsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    background-color: white;
    border-radius: 20px;
    padding: 24px 40px 24px 0px;
    @media screen and (max-width: 475px) {
      padding: 24px 22px 24px 16px;
    }
    label {
      color: #3a3d42;
      font-weight: 500;
    }
  }

  .tagsContainer {
    padding: 26px 32px;
    align-items: flex-start;
    font-weight: 500;
    height: 100%;
    font-size: 14px;
  }

  .tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  :global {
    .MuiFormControl-root {
      max-width: 256px;
      min-width: 160px;
      & > .MuiButtonBase-root {
        padding: 0 !important;
      }
    }
    .UploadInput {
      max-width: 160px;
    }
  }
  .education {
    display: flex;
    gap: 39px;
    width: 100% !important;
  }
  @media screen and (max-width: 364px) {
    .inputsContainer {
      .education:nth-child(6) {
        div {
          gap: 16px;
          @media screen and (max-width: 343px) {
            gap: 9px;
          }
          @media screen and (max-width: 331px) {
            gap: 6px;
          }
          label {
            transform: translate(-8px, 0px) scale(1) !important;
          }
        }
      }
    }
  }
}

.isTablet {
  display: none;
  @media screen and (max-width: 896px) {
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #3a3d42;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 124.4%;
    text-align: center;
    color: #3a3d42;
  }
  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
}

.ModalOver2MB {
  :global {
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }
}
