.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .blog {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: white;
    max-width: 608px;
    border-radius: 20px;
    padding: 18px 24px;
    img {
      max-width: 560px;
      width: 100%;
    }
    & > div {
      & > p {
        font-size: 14px;
      }
      & > ul {
        padding-left: 16px;
      }
    }

    * {
      word-break: break-word;
    }
  }
}
