@import '@styles/vars.scss';

.container {
  .tabletTitle {
    display: none;
    @media screen and (max-width: 896px) {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 128.9%;
      color: $green-lite;
      margin-bottom: 4px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }

  .search_input {
    display: flex;
    gap: 21px;
    width: 100%;
    align-items: center;
    justify-content: end;

    :global {
      .MuiAutocomplete-root {
        width: auto !important;
        background: rgba($green-lite, 0.1) !important;
      }
      .MuiFormControl-root {
        flex: 2;
      }
      .MuiInputBase-input {
        font-weight: 600;
        font-size: 12px;
        color: $green-lite;
      }
      .MuiFormLabel-root {
        font-family: 'Raleway', sans-serif;
        font-weight: 500 !important; 
        font-size: 14px;
        max-width: 100%;
        text-align: end;
        flex: 1 1;
        white-space: pre-wrap !important;
        overflow: visible !important;
      }
    }
  }

  .inputContainer {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 24px 40px;
    gap: 8px;
    text-align: end;
  }

  .changePassText {
    color: $green;
    font-size: 12px;
    cursor: pointer;
  }

  :global {
    .MuiFormControl-root {
      max-width: 256px !important;
    }
  }
  .change_password {
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: all 0.5s ease-in-out;
    height: 0;
    &_active {
      transition: all 0.5s ease-in-out;
      visibility: visible;
      opacity: 1;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #3a3d42;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 124.4%;
    text-align: center;
    color: #3a3d42;
  }
  &__success {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  &__password {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    .password_error {
      text-align: end;
      margin-top: 8px;
    }
    .button_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      .button_cancel {
        background: $orange !important;
      }
    }
  }
}
