@import '@/styles/vars.scss';

.proBanner {
  position: relative;
  text-align: center;
  padding-bottom: 10px;
  .mobile_close {
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: 0;

    position: absolute;
    top: 10px;
    right: 5px;
    width: 24px !important;
    font-size: 16px;

    color: #809c81;
  }
  .proBanner_orangeButton {
    background: #f16526 !important;
  }

  .more {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $white;
    text-decoration: none;
  }

  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    color: $black;
    margin-bottom: 10px;

    .orange {
      color: $orange;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $black;
    padding: 0px 5px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left !important;
    padding: 8px 18px;

    p {
      text-align: left !important;
      padding-left: 0;
    }

    img {
      max-height: 120px;
    }

    .proBanner_orangeButton {
      margin-bottom: -50px;
      width: 180px !important;
      min-width: 150px !important;
      padding: 12px 0px !important;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 7px 5px;
    .proBanner_orangeButton {
      display: none;
    }

    h4 {
      max-width: 90%;
      font-size: 10px;
      margin-bottom: 3px;
    }

    p {
      font-size: 8px;
      margin: 0px;
      line-height: 128.9%;
    }

    img {
      max-height: 68px;
    }

    .proBanner_orangeButton_mobile {
      display: none !important;
      font-size: 10px !important;
      padding: 10px 0px !important;
      min-width: 140px !important;
      width: 140px !important;
    }
  }
}
.proBanner_orangeButton {
  text-align: center;
  padding-bottom: 10px;
  background: $orange !important;
  background-color: $orange !important;
  padding-right: 25px !important;
  padding-left: 25px !important;

  &_mobile {
    display: none !important;

    @media screen and (max-width: 550px) {
      display: none;
      margin-bottom: 0px !important;
    }
  }
  @media screen and (max-width: 550px) {
    display: none;
  }
}
