.modal{
  padding-left: 12px !important;
  padding-right: 12px !important;

  @media screen and (max-width: 1000px){
    padding: 10px !important;
  }
}

.photo_slider{
  max-width: 60vw;
  position: relative;
  padding: 20px 0px;

  :global{
    .swiper-slide{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
      @media screen and (max-width: 700px){
        height: initial !important;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    .swiper-button-next, .swiper-button-prev{
      color: #212121 !important;
    }
  }
  img{
    max-width: 90%;
    min-height: 30vh !important;
    max-height: 70vh !important;
    margin: 0 auto;
    user-select: none;
  }

  @media screen and (max-width: 1100px){
    max-width: 90vw;

    img{
      min-height: initial !important;
    }
  }

  @media screen and (max-width: 1000px){
    padding: 0 !important;
    img{
      max-width: 100%;
    }
    :global{
      .swiper-button-next, .swiper-button-prev{
        display: none !important;
      }
    }
  }
}