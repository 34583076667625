@import '@styles/vars.scss';

.modal {
  max-width: 500px;
  text-align: center;

  .link {
    color: $green;
    &:hover {
      cursor: pointer;
    }
  }
}
