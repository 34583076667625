.imagePreview {
    max-height: 208px;
    background-color: white;
    width: inherit !important;
    height: inherit !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
