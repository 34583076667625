.container {
  max-width: 630px;
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  div {
    width: 100%;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  :global {
    .MuiButtonBase-root {
      padding: 12px 33px !important;
      align-self: flex-end;
      font-family: 'Montserrat';
      font-size: 12px;
      line-height: 15px;
    }

    .MuiInputBase-root {
      min-height: 144px;
      textarea {
        min-height: 144px;
      }
    }
  }
}
