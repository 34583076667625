@import '@/styles/vars.scss';
.container {
  display: flex;
  gap: 21px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .label {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: end;
    flex: 1;
    white-space: pre-wrap !important;
    overflow: visible !important;
  }

  .textField {
    display: block !important;
    width: 100%;

    input,
    textarea {
      padding: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      font-family: 'Raleway', sans-serif !important;
      &::placeholder {
        color: $green-lite !important;
        opacity: 0.9 !important;
      }
      color: $green-lite !important;
    }

    textarea {
      min-height: 126px;
    }

    border: none !important;
    div,
    fieldset {
      outline: none;
      border-radius: 10px;
      border: none !important;
      border-color: rgba($green-lite, 0.1) !important;
    }
  }

  .input {
    background: rgba($green-lite, 0.1);
    padding: 9px 15px;
    width: 100%;
  }

  :global {
    & .MuiFormControl-root {
      flex: 2;
    }

    & .MuiInputBase-root {
    }
  }

  @media only screen and (max-width: 500px) {
    .textField {
      input,
      textarea {
        font-size: 16px;
      }
    }
  }
}
