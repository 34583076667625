@import '@styles/vars.scss';

.infoButton {
  color: $green-lite;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 475px) {
    margin-top: 17px;
    margin-left: 15px;
  }
}
