@import "@/styles/vars.scss";

.form{
  min-width: 192px;
  text-align: center;

  button{
    margin-top: 20px !important;
  }
}

.form_title{
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;

  color: $black;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.form_text{
  margin: 0 auto;
  max-width: 85%;
  font-weight: 500;
  font-size: 12px;
  line-height: 124.4%;
  text-align: center;
  margin-bottom: 18px;
  color: $black;
}

.form_city {
  display: flex;

  .search_input {
    flex: 100%;
    margin-top: 2px;
    margin-bottom: 2px;

    :global {
      .MuiAutocomplete-root {
        width: auto !important;
        background: rgba($green-lite, 0.1) !important;
      }
    }
  }
}

