@import '@styles/vars.scss';

.settingsNavList {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 24px 32px;
  list-style: none;
  gap: 8px;

  .link {
    text-decoration: none;
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 128.9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
    border-radius: 8px;
  }

  .circle {
    display: none;
    background-color: $green-lite;
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }

  .isActive {
    .circle {
      display: block;
    }
    font-weight: 600;
    background-color: rgba($green-lite, 0.1);
    color: $green-lite;
  }
}
