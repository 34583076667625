@import '@styles/vars.scss';

.container {
  display: flex;
  gap: 16px;
  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 504px;

    :global {
      & .MuiInputLabel-root {
        font-weight: 600;
      }
    }
  }
  
  .module_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    font-weight: 600;
  }

  @media only screen and (max-width: 1200px) {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 610px) {
    .form {
      max-width: 475px;
    }
  }
  @media only screen and (max-width: 500px) {
    .form {
      max-width: 95vw;
    }
  }
}
