@import '@/styles/vars.scss';

.sider {
  max-height: 100%;
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    max-height: 100000px !important;
    overflow-y: visible;
  }
}

.sider_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  padding-right: 10px;

  .liluf_logo {
    min-width: 104px;
  }

  .header__country {
    border: none;
    background: transparent;
    color: $green-lite;
    display: flex;
    align-items: center;
    cursor: pointer;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    svg {
      margin-right: 7px;
      margin-left: 15px;
      min-width: 10px;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1; /* количество строк */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      text-align: left;
    }
  }
}

.authed_user_top {
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: space-between;

    .sider_header {
      flex: 0 1 35%;
    }
  }

  @media screen and (max-width: 900px) {
    .sider_tablet_menu {
      flex: 0 1 65%;
    }
  }
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    .sider_header {
      flex: 0 1 100%;
    }
    .sider_tablet_menu {
      flex: 0 1 100%;

      &__links {
        gap: 18px;
      }
    }
  }
}

.sider_tablet_menu {
  display: none;
  align-items: center;
  background: #fff;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 10px;
  justify-content: space-between;
  flex-direction: row;

  &__info {
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    .info_avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 1px solid #c6d9c6;
      overflow: hidden;
      border-radius: 50%;

      margin-right: 16px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 18px;

    svg {
      width: 20px;
    }

    .link_big {
      svg {
        width: 23px;
        height: auto;
      }
    }

    .link_fav {
      svg {
        path {
          fill: rgb(128, 156, 129);

          fill-opacity: 1;
          column-fill: auto;
        }
      }
    }

    @media screen and (max-width: 750px) {
      svg {
        width: 15px;
      }

      .link_big {
        svg {
          width: 19px;
          height: auto;
        }
      }
    }

    @media screen and (max-width: 700px) {
      gap: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex: 0 1 60%;
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.sider_mobile_menu {
  display: none;

  @media screen and (max-width: 450px) {
    display: block;
    background: #fff;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 10px;
    padding: 8px 15px;
  }
}

.sider_about_us {
  margin-top: 26px;

  .about_us_title {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .about_us_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    /* text */
    color: $black;
  }
}

.sider_block {
  background: #ffffff;

  border-radius: 20px;
  margin-bottom: 8px;
  padding: 11px 20px;

  &_short_info {
    padding: 16px 24px;
  }

  &_menu {
    padding: 21px 33px;
    min-width: 280px;
  }
}

.green {
  color: $green;
}

.sider_header_not_auth {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.not_authed {
  .sider_header_mobile_not_auth {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    display: flex;
    gap: 25px;
    .not_authed_form {
      flex: 0 1 280px;
    }

    .sider_about_us {
      flex: 0 1 405px;
    }

    .sider_header_mobile_not_auth {
      display: block;
    }
  }

  @media screen and (max-width: 700px) {
    .not_authed_form {
      flex: 0 1 55%;
    }
    .sider_about_us {
      flex: 0 1 45%;
    }
  }

  @media screen and (max-width: 550px) {
    .not_authed_form {
      flex: 0 100%;
    }
    .sider_about_us {
      display: none;
    }
  }
}

.authed_user {
  .mobile_sider_block {
    display: none;
    @media screen and (max-width: 1200px) {
      display: block;
      background: #fff;
      border-radius: 10px;
    }
    &_onProPage {
      display: none;
    }
  }
}
