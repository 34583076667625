@import '@/styles/vars.scss';

.message {
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-feature-settings: 'pnum' on, 'lnum' on;
  &_messageWrap {
    width: max-content;
    word-break: break-all;
    max-width: 300px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 12px 16px;
    gap: 16px;
  }
  &_reciever {
    background-color: $grey;
    border-radius: 20px 20px 20px 0px;
    .extraInfo {
      justify-content: flex-end !important;
    }
    &::before {
      content: '';
      position: absolute;
      width: 11px;
      left: -10px;
      bottom: 0px;
      height: 20px;
      background-color: $grey;
      clip-path: polygon(
        50% 60%,
        100% 20%,
        100% 60%,
        100% 100%,
        0% 100%,
        50% 60%,
        10% 20%
      );
    }
  }
  &_sender {
    align-self: flex-end;
    background-color: $white;
    border-radius: 20px 20px 0px 20px;
    &::before {
      content: '';
      position: absolute;
      width: 11px;
      right: -10px;
      bottom: 0px;
      height: 18px;
      background-color: $white;
      clip-path: polygon(
        100% 100%,
        100% 20%,
        100% 60%,
        100% 100%,
        0% 100%,
        0% 0%,
        0% 10%
      );
    }
  }
  &_image {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    img {
      max-width: 300px;
      max-height: 300px;
      border-radius: 20px 20px 0px 0px;
    }
    .extraInfo {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      width: 100%;
      padding: 10px 13px 10px 13px;
    }
  }

  .extraInfo {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &_time {
    color: $green;
    word-break: normal;
  }
}
