@import '@styles/vars.scss';

.container {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 42px 18px 24px;
  border-radius: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  max-width: 920px;

  .details {
    display: flex;
    gap: 14px;
    flex-direction: column;
    justify-content: space-between;

    .location {
      color: $green-lite;
      font-size: 14px;
      display: flex;
      gap: 9px;
    }
  }

  .about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;

    .reviews {
      display: flex;
      flex-direction: column;
      gap: 6px;
      justify-content: space-between;
    }

    .like {
      fill: $green-lite;
      fill-opacity: 1;
      column-fill: auto;
      &:hover {
        fill: $orange;
        cursor: pointer;
      }
    }

    .liked {
      stroke: transparent !important;
      fill: $orange;
      fill-opacity: 1;
    }
  }

  .light {
    font-weight: 500;
  }

  .bold {
    font-weight: 700;
  }

  .green {
    color: $green;
  }

  .allReviews {
    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 698px) {
      display: none;
    }
  }

  .button {
    padding: 12px 21px !important;
    text-decoration: none !important;
  }

  .noDecoration {
    text-decoration: none;
  }

  .messageSVG {
    display: none;
  }

  @media screen and (max-width: 698px) {
    padding: 11px 15px 11px 15px;
    border-radius: 10px;

    .noDecoration {
      text-decoration: none;
      height: 40px;
    }

    .details {
      gap: 4px;
      font-size: 13px;

      .location {
        font-size: 8px;
        gap: 4px;
        align-items: center;
        svg {
          width: 8px;
        }
      }
    }

    .reviews {
      font-size: 14px;
    }

    .button {
      display: none;
    }

    .messageSVG {
      display: block;
      background-color: $green;
      border-radius: 50%;
      padding: 10px 0px;
      width: 40px;
      height: 40px;
      path {
        stroke: white;
      }
    }

    .allReviews {
      display: none;
    }

    .like {
      background-color: $grey;
      border-radius: 50%;
      fill: $green-lite;
      padding: 10px 0px;
      width: 40px;
      height: 40px;
      column-fill: auto;
      &:hover {
        fill: $orange;
        cursor: pointer;
      }
    }
    .complaintsButton {
      margin: 0px !important;
      :global {
        .textInfo {
          display: none;
        }
      }
    }
  }
}
