.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .radioGroup {
    display: flex;
    flex-direction: row !important;
    gap: 8px;
  }

  .favoriteUsersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .cardsContainer {
    max-width: 950px;

    @media only screen and (max-width: 1336px) {
      max-width: 630px;
    }
  }

  .contacts__block {
    padding: 16px 24px;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 8px;
    max-width: 295px;
    .block__phone {
      width: 100%;
      background: rgba(128, 156, 129, 0.1) !important;

      span {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: -1px;
        margin-left: 9px;
        color: #809c81;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .contacts__block {
      padding: 10px 19px;
      border-radius: 10px;
    }
  }
  @media only screen and (max-width: 450px) {
    .favoriteUsersContainer {
      flex-direction: column;
    }
    .contacts__block {
      flex: 1;
      max-width: 100%;
    }
  }
}

.empty_title {
  text-align: center;
  margin-top: 30px;
  font-weight: 400;
}
