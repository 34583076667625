@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@import './vars.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  background: linear-gradient(142.1deg, #ddf1d6 0%, #f7efe4 100%);
  font-family: 'Raleway', sans-serif;
  overflow-x: auto;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding-top: 32px;
  padding-bottom: 17px;
  padding-right: 30px;
  padding-left: 30px;

  @media screen and (max-width: 600px) {
    padding-top: 32px;
    padding-bottom: 17px;
    padding-right: 28px;
    padding-left: 28px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.form_error {
  color: red;
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.green {
  color: $green;
}

.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-justify {
  text-align: justify;
}
