.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 540px;
  width: 100%;
  & p {
    font-size: 12px;
    font-weight: 700;
  }
}
